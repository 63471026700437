<template>
	<div class="wrap">
		<el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<el-alert title="操作提示" type="success" class="margin-bottom">
			<div>
				1. 标识“*”的选项为必填项，其余为选填项。<br />
				2. 请按提示栏信息进行商品添加。
			</div>
		</el-alert>
		<div class="box">
			<div class="form_box">
				<!-- {{form.permissions}} -->
				<el-form ref="form" :model="form" label-width="80px" v-loading="loading">
					<el-form-item label="商品名称" prop="name" :rules="rules.required">
						<el-input v-model="form.name"></el-input>
						<span class="text-grey margin-left">商品名称最多可输入100个字符！</span>
					</el-form-item>
					<el-form-item label="商品分类" prop="classify" :rules="rules.required">
						<el-select v-model="form.classify" placeholder="请选择">
							<el-option v-for="item in classify" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="商品编码" prop="code">
						<el-input v-model="form.code"></el-input>
					</el-form-item>
					<el-form-item label="商品描述" prop="des">
						<el-input v-model="form.des" type="textarea" :autosize="{ minRows: 4}"></el-input>
					</el-form-item>
					<el-divider></el-divider>
					<el-form-item label="商品售价" prop="current_price" :rules="rules.required">
						<el-input-number v-model="form.current_price" :precision="2" :step="10" :max="9999" :min="0">
						</el-input-number>
					</el-form-item>
					<el-form-item label="原价" prop="original_price">
						<el-input-number v-model="form.original_price" :precision="2" :step="10" :max="9999" :min="0">
						</el-input-number>
					</el-form-item>
					<el-form-item label="成本价" prop="cost_price">
						<el-input-number v-model="form.cost_price" :precision="2" :step="10" :max="9999" :min="0">
						</el-input-number>
					</el-form-item>
					<el-form-item label="销售提成" prop="sales_commission">
						<el-input-number v-model="form.sales_commission" :precision="2" :step="10" :max="9999" :min="0">
						</el-input-number>
					</el-form-item>
					<el-form-item label="服务提成" prop="server_commission">
						<el-input-number v-model="form.server_commission" :precision="2" :step="10" :max="9999"
							:min="0"></el-input-number>
					</el-form-item>
					<el-divider></el-divider>
					<el-form-item label="缩略图" prop="cover">
						<el-upload class="avatar-uploader" :action="uploadImageUrl" :show-file-list="false"
							:on-success="coverUploadSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="form.cover" :src="form.cover" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="商品介绍" prop="rich_content">
						<div style="width: 750px;">
							<quill-editor ref="myQuillEditor" v-model="form.rich_content" :options="editorOption" class="ql-editor-class">
							</quill-editor>
						</div>
						<!-- 图片上传组件辅助-->
						<el-upload class="avatar-uploader-editoer" :action="uploadImageUrl" :headers="headers"
							:show-file-list="false" :on-success="editorUploadSuccess" :on-error="editorUploadError"
							:before-upload="beforeAvatarUpload">
						</el-upload>
					</el-form-item>
					<el-form-item label="上架">
						<el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="onSubmit()">保存</el-button>
						<!-- <el-button type="primary" @click="onSubmit()">保存并继续添加</el-button> -->
						<el-button @click="$router.back()">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>
<script>
	import rules from '../../common/common.rules.js'
	import axios from 'axios'
	// 工具栏配置
	const toolbarOptions = [
		["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
		// ["blockquote", "code-block"], // 引用  代码块
		// [{ header: 1 }, { header: 2 }], // 1、2 级标题
		// [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
		// [{ script: "sub" }, { script: "super" }], // 上标/下标
		// [{ indent: "-1" }, { indent: "+1" }], // 缩进
		// [{'direction': 'rtl'}],                         // 文本方向
		[{ size: ["small", false, "large", "huge"] }], // 字体大小
		[{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
		[{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
		// [{font: []}], // 字体种类
		[{align: []}], // 对齐方式
		// ["clean"], // 清除文本格式
		// ["link", "image", "video"] // 链接、图片、视频
		["image"]
	];
	export default {
		data() {
			return {
				uploadImageUrl: axios.defaults.baseURL + this.$api.common.UPLOAD_URL,
				rules,
				classify: [],
				loading: false,
				tagOptions: [],
				editorOption: {
					placeholder: "请输入游戏介绍",
					modules: {
						toolbar: {
							container: toolbarOptions,
							// container: "#toolbar",
							handlers: {
								image: function(value) {
									if (value) {
										// 触发input框选择图片文件
										document.querySelector(".avatar-uploader-editoer input").click();
									} else {
										this.quill.format("image", false);
									}
								}
							}
						}
					}
				},
				headers: {
					// token: sessionStorage.token
				}, // 有的图片服务器要求请求头需要有token
				form: {
					id: this.$route.params.id, // int 服务ID
					code: undefined,
					name: undefined, // string 产品名称
					classify: undefined, // int 分类ID
					cover: undefined, // string 封面缩略图
					des: undefined, // string 服务描述
					cost_price: 0, // decimal 成本价格
					original_price: 0, // decimal 原价
					current_price: 1, // decimal 现价
					sales_commission: 0, // decimal 销售提成
					server_commission: 0, // decimal 服务提成
					status: 1, // 上架
					rich_content: '', // 富文本
				}
			};
		},
		computed: {
			title() {
				if (this.form.id) {
					return '编辑商品'
				} else {
					return '新增商品'
				}
			}
		},
		mounted() {
			// 获取商品分类
			this.$api.classify.getClassify(this.$api.classify.GOODS).then(res => {
				this.classify = res.data
			})
			if (this.form.id) {
				this.$api.goods.getGoodsById({
					id: this.form.id
				}).then(res => {
					this.form = res.data
				})
			}
			this.headers.Authorization = this.vuex_token;
		},
		methods: {
			onSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						this.$api.goods.save(this.form).then(res => {
							loading.close();
							if (res.code == 200) {
								// 保存成功
								this.$message.success(`保存成功`);
								this.$router.back();
							} else {
								this.$message.error(res.msg);
							}
						}).catch(err => {
							loading.close();
							this.$alert(err);
						});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			},
			coverUploadSuccess(res) {
				this.form.cover = res.data
				this.loading = false;
				// this.form.cover = URL.createObjectURL(file.raw);
			},
			// 富文本图片上传前 and 封面上传前 公用
			beforeAvatarUpload(file) {
				console.log(file.type)
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
				const isLt5M = file.size / 1024 / 1024 < 5;

				if (!isJPG) {
					this.$message.error('只能上传图片文件!');
				}
				if (!isLt5M) {
					this.$message.error('图片大小不能超过 5MB!');
				}
				// 校验成功开始上传，打开loading
				this.loading = isJPG && isLt5M;
				return isJPG && isLt5M;
			},
			editorUploadSuccess(res) {
				// res为图片服务器返回的数据
				// 获取富文本组件实例
				let quill = this.$refs.myQuillEditor.quill;
				// 如果上传成功
				if (res.code == 200) {
					// 获取光标所在位置
					let length = quill.getSelection().index;
					// 插入图片  res.url为服务器返回的图片地址
					quill.insertEmbed(length, "image", res.data);
					// 调整光标到最后
					quill.setSelection(length + 1);
				} else {
					this.$message.error("图片插入失败");
				}
				// loading动画消失
				this.loading = false;
			},
			// 富文本图片上传失败
			editorUploadError() {
				// loading动画消失
				this.loading = false;
				this.$message.error("图片插入失败");
			},
		},
	};
</script>

<style scoped lang="scss">
	.form_box {

		.el-input,
		.el-textarea {
			width: 500px;
		}
	}

	.avatar-uploader ::v-deep .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;

		&:hover {
			border-color: #409EFF;
		}
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}

	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}

	.ql-editor-class {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		line-height: 1;
		height: 100%;
		outline: none;
		padding: 0 !important;
		tab-size: 4;
		-moz-tab-size: 4;
		text-align: left;
		word-wrap: break-word;
	}
</style>
